.auth_container {
  width: 100%;
  min-height: calc(100vh - var(--navbar-height));
  display: flex;
  align-items: stretch;
  margin-top: var(--navbar-height);
  position: relative;
}
.auth_content {
  flex: 1;
}
