.container {
  width: 100%;
  height: 50px;
  transition: height ease 0.2s;
  overflow: hidden;
}

.heading {
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 10px;
}

.content {
  width: 100%;
  padding-left: 20px;
  height: auto;
}

.header {
  position: relative;
}

.drop_icon {
  position: absolute;
  top: 30%;
  right: 10px;

  margin-left: auto;
  color: var(--dark-gray-color);
}
