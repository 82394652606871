.container {
  position: fixed;
  top: var(--navbar-height);
  left: 0;
  bottom: 0;
  right: 0;
  z-index: var(--drawer-z);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
}

.content {
  background-color: var(--light-blue-color);
  border-right: 2px #e6e8ec solid;
  padding: 20px 10px;
  width: var(--drawer-width);
  height: 100%;
  overflow-y: auto;
}

.drawer_item {
  width: 100%;
  display: block;
  display: flex;
  align-items: center;
  height: 60px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 6px;
  text-decoration: none;
  padding-left: 5px;
}
.drawer_item:hover {
  background-color: var(--primary-color);
}

.drawer_item:hover > * {
  color: white !important;
}

.drawer_icon {
  color: var(--text-color);
  font-size: 20px;
  flex-basis: 10%;
}

.drawer_text {
  font-size: var(--para-m);
  margin-left: 10px;
  font-weight: 500;
  color: var(--dark-gray-color);
}

.active.active {
  color: var(--primary-color);
}

.slideIn {
  animation: slideIn 0.3s ease forwards;
}

.slideOut {
  animation: slideOut 0.3s ease forwards;
}

.active_overlay {
  animation: fullOpacity 0.3s ease forwards;
}

.de_active_overlay {
  animation: zeroOpacity 0.3s ease forwards;
}

.head {
  height: 130px;
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  padding: 10px;
  border-bottom: 1px #e6e8ec solid;
  margin-bottom: 10px;
}
.headerIcon_section {
  flex: 1;
  display: flex;
  align-items: center;
}
.header_desc {
  flex-basis: 30px;
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: center;
  font-size: var(--para-m);
  text-align: left;
  font-weight: bolder;
}
.head_email {
  font-size: var(--para-s);
  margin-top: 5px;
  color: var(--dark-gray-color);
}
.userIcon_box {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--medium-gray-color);
  font-size: 30px;
  color: white;
}

@keyframes slideIn {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideOut {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@keyframes fullOpacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes zeroOpacity {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media (min-width: 1024px) {
  .container {
    flex-basis: var(--drawer-width);
    position: unset;
    background-color: var(--light-blue-color);
    position: sticky;
    top: var(--navbar-height);
    left: 0;
    right: 0;
    max-height: calc(100vh - var(--navbar-height));
  }

  .content {
    overflow: auto;
  }

  .overlay {
    display: none;
  }

  .drawer_item {
    height: 40px;
  }
}
