.container {
  font-size: var(--btn-m);
  font-weight: 500;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.primary {
  background-color: var(--primary-color);
  color: white;
}
.secondary {
  background-color: white;
  border: 1px #e0e5e9 solid;
  border-radius: 3px;
  color: var(--secondary-color);
  font-weight: bold;
}

.at {
  width: auto;
  height: auto;
}

.sm {
  width: 80px;
  height: 50px;
}

.md {
  width: 150px;
  height: 50px;
}

.lg {
  width: 250px;
  height: 100px;
}

.disabled {
  background-color: var(--dark-gray-color);
  cursor: not-allowed;
}
button:disabled {
  cursor: not-allowed;
}
