.tool_cont {
  position: fixed;
  max-width: 200px;
  min-width: 150px;
  padding: 10px;
  background-color: black;
  color: white;
  font-size: var(--para-xs);
  text-align: center;
  display: flex;
  border-radius: 6px;
  z-index: var(--tooltip-z);

  --triangle-width: 30px;
  --triangle-height: 10px;
}

.visible_anim {
  animation: bounce 0.3s ease-in-out forwards;
}

.tool_text {
  width: 100%;
  font-weight: bold;
  text-transform: none;
}

.traingle {
  position: absolute;
  width: var(--triangle-width);
  height: var(--triangle-height);
  clip-path: polygon(0 0, 50% 0, 100% 100%, 0 100%, 50% 0);
  background-color: black;
}

.top_right {
  top: calc(-1 * var(--triangle-height) * 0.5);
  right: 5px;
}

.top_middle {
  top: calc(-1 * var(--triangle-height) * 0.5);
  left: 45%;
}

.top_left {
  top: calc(-1 * var(--triangle-height) * 0.5);
  left: 0;
}

.bottom_left,
.bottom_middle,
.bottom_right {
  clip-path: polygon(0 0, 100% 0, 50% 100%, 0 0);
}

.bottom_right {
  bottom: calc(-1 * var(--triangle-height) * 0.5);
  right: 0;
}

.bottom_middle {
  bottom: calc(-1 * var(--triangle-height) * 0.5);
  left: 45%;
}

.bottom_left {
  bottom: calc(-1 * var(--triangle-height) * 0.5);
  left: 0;
}

@keyframes bounce {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(0.9);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
