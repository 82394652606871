.container {
  width: 100%;
  height: var(--navbar-height);
  background-color: white;
  box-shadow: var(--shadow-m);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: var(--navbar-z);
}

.btn_section {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toggle_btn {
  border: none;
  background-color: transparent;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 2px #e6e8ec solid;
  color: var(--secondary-color);
  cursor: pointer;
}

.logo {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.nav_logo_box {
  width: 350px;
  display: none;
  align-items: center;
  justify-content: space-around;
  text-transform: uppercase;
  font-size: var(--para-s);
}

.data_box {
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: var(--para-s);
  font-weight: bolder;
}
.count {
  font-weight: bolder;
  margin-left: 3px;
  font-size: var(--para-m);
}

.btn {
  background-color: transparent;
  padding: 10px 15px;
}

.version_cont {
  width: 200px;
  margin-left: 10px;
  height: 30px;
}
.version_select_box {
  width: 100%;
  height: 100%;
  font-size: 10px;
}
.version_select {
  padding: 0;
  padding-left: 5px;
}

@media (min-width: 768px) {
  .nav_logo_box {
    display: flex;
  }
}
@media (min-width: 1024px) {
  .btn_section {
    display: none;
  }

  /* .toggle_btn {
    display: none;
  } */
}
